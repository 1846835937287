import { PromotionUtils } from '../utils'
import PROMOTION_CONFIG from '../config'
import { template, htmlDecode } from '@shein/common-function'
const { percentNum, percentNumWithoutSign, discountNumCn } = PromotionUtils
const { DEBUG } = PROMOTION_CONFIG

/**
 * 添加促销提示语
 * @param {Number} typeId [活动类型id]
 * @param {Object} integrityPromotionInfo [异步获取的活动信息集合]
 * @param {String, Number} id [活动id]
 * @param {Object} language [多语言]
 * @param {Object} content [促销活动整合内容]
 * @param {String} scence [场景参数，区分购物车和列表详情页]
 */
function transformPromotionTips ({ language = {}, integrityPromotionInfo = {}, originPromotionInfo = {}, id = '', typeId = '', scence = '', options = {} }) {
  const content = {}
  const isAsync = Boolean(integrityPromotionInfo[id]) //存在异步数据
  const mixedPromotionInfo = Object.assign({}, integrityPromotionInfo[id] || {}, originPromotionInfo)
  let { rangeList = [], rules = [], ruleCrondType = '', ruleType = '', buyLimit = '', discountValue = '', singleLimitType = '', singleNum = '', limitMode = 0, orderNum = '', soldNum = '', endTimestamp = '', isFullShop, memberNum = '', mainProductRange, brandName } = mixedPromotionInfo
  const { lang } = gbCommonInfo
  content.id = id // 活动id
  content.typeId = typeId // 活动类型id
  content.tips = { text: '', list: [] } // 列表页tips
  content.tip = '' // 购物车展示的提示语
  content.difference = { text: '', diff: '', diffAmount: null, diffType: '' } // 差额
  content.action = 0 // 0: 无操作; 1: Add，可以添加商品; 2: Pick，可以选择商品
  content.tipsIndex = 0 // 当前使用的提示语index (由于购物车可能有差额提示，导致展示更高一档提示语，此index为当前达到的档次)
  content.lable = '' // 活动标签
  content.style = {} // 促销样式
  content.rules = rules || [] // 促销样式

  if (DEBUG) {
    PromotionUtils.logBrowser('transformPromotionTips', [id, typeId])
    PromotionUtils.logBrowser('integrityPromotionInfo', integrityPromotionInfo[id])
  }

  function calcDifferenceTip (diff = '', diffType = 1, diffAmount = {}) {
    if (diffType == 1) {
      content.difference.text = template(diffAmount.amountWithSymbol, language.SHEIN_KEY_PWA_15979)
    } else if (diffType == 2) {
      content.difference.text = template(diff, language.SHEIN_KEY_PWA_15980)
    }
    content.difference.diff = diff
    content.difference.diffType = diffType
    content.difference.diffAmount = diffAmount
  }

  /**
   * 1.买折
   * 差额提示: -
   * 多档次: -
   * @ruleType sale_discount: 按特价折扣; discount: 按原价折(默认)
   */
  if (typeId == 1) {
    if (isAsync) {
      if (ruleType == 'sale_discount') {
        content.tips.list[0] = template(buyLimit, percentNumWithoutSign(discountValue), language.SHEIN_KEY_PWA_18322)
      } else {
        content.tips.list[0] = template(buyLimit, percentNumWithoutSign(discountValue), language.SHEIN_KEY_PWA_18321)
      }
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15880

  /**
   * 2.买赠
   * 差额提示: -
   * 多档次: -
   */
  } else if (typeId == 2) {
    if (isAsync) {

      if (mainProductRange == 4) {
        content.tips.list[0] = template(buyLimit, brandName, language.SHEIN_KEY_PWA_20397)
      } else {
        content.tips.list[0] = template(buyLimit, language.SHEIN_KEY_PWA_20396)
      }
      content.tips.text = content.tips.list[0] || ''
      
    }
    content.lable = mainProductRange == 4 ? template(brandName, language.SHEIN_KEY_PWA_20401) : language.SHEIN_KEY_PWA_15881

    /**
     * 3.限时活动/折上折
     */
  } else if (typeId == 3) {
    content.tips.text = options.isMoreDiscount ? language.SHEIN_KEY_PWA_18130 : language.SHEIN_KEY_PWA_15811
    // content.lable = language.SHEIN_KEY_PWA_15811

    /**
     * 4.满赠（全场）
     */
  } else if (typeId == 4) {
    content.lable = language.SHEIN_KEY_PWA_15881

    /**
     * 8.app专享
     */
  } else if (typeId == 8) {
    content.lable = language.SHEIN_KEY_PWA_18045

  /**
   * 9.买N折N
   * 差额提示: -
   * 多档次: 是
   * @ruleType sale_discount: 按特价折扣; discount: 按原价折(默认)
   */
  } else if (typeId == 9) {
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        if (ruleType == 'sale_discount') {
          content.tips.list[i] = template(r.buyLimit.amount, ((1 - r.discountValue.amount) * 100).toFixed(0), language.SHEIN_KEY_PWA_16886)
        } else {
          if (['hk', 'tw'].includes(lang)) {
            content.tips.list[i] = template(r.buyLimit.amount, Number((r.discountValue.amount * 10).toFixed(1)), language.SHEIN_KEY_PWA_14963)
          } else {
            content.tips.list[i] = template(r.buyLimit.amount, ((1 - r.discountValue.amount) * 100).toFixed(0) + '%', language.SHEIN_KEY_PWA_14963.replace('%', ''))
          }
        }
      })
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15880

  /**
   * 10.常规闪购 & 11.直播闪购
   * 差额提示: -
   * 多档次: -
   * @singleLimitType 1: 按产品限购; 2: 按订单限购; 3: Both 1 & 2
   * @singleNum 限购数，singleLimitType=1 为按产品限购数，singleLimitType=2 为按订单限购数
   * @ordernum 按订单限购数，仅在 singleLimitType=3 时生效
   * @memberNum 按用户限购数，仅在 memberNum > 0 时生效
   * 场景：
   * 1、单品限购+用户限购singleNum\memberNum有值 2、用户限购 仅memberNum有值
   * 3、订单限购+用户限购orderNum\memberNum有值取小的那个值 4、单品限购+订单限购+用户限购singleNum\orderNum\memberNum都有值，取singleNum+orderNum\memberNum值小的那个

   */
  } else if (typeId == 10 || typeId == 11) {
    if (Object.keys(originPromotionInfo).length) {
      
      if (Number(singleNum)) {
        const currentTimestamp = Date.now() / 1000
        const purchaseLimitText = limitMode ? template(singleNum, language.SHEIN_KEY_PWA_22832) : template(singleNum, language.SHEIN_KEY_PWA_17193)
        content.tips.list[0] = Number(buyLimit) > Number(soldNum) && currentTimestamp < Number(endTimestamp) ? purchaseLimitText : ''
      }
      content.tips.text = content.tips.list[0] || ''
      
    }
    content.lable = scence === 'detail' ? language.SHEIN_KEY_PWA_15055 : ''

  /**
   * 13.加价购
   * 差额提示: -
   * 多档次: 是
   * @ruleType quantity: 满件加价购; price: 满元加价购(默认)
   * @ruleDimension price: 以固定金额加购; discount: 以折扣加购
   */
  } else if (typeId == 13) {
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        const ruleDimension = r.ruleDimension
        const buyLimitAmountWithSymbol = r.buyLimit.amountWithSymbol
        const buyLimitAmount = r.buyLimit.amount
        const redemptionPriceAmountWithSymbol = r.redemption[0].price.amountWithSymbol
        const redemptionPriceAmount = r.redemption[0].price.amount

        if (ruleType == 'quantity') {
          // 满件
          if (ruleDimension == 'price') {
            content.tips.list[i] = template(buyLimitAmount, redemptionPriceAmountWithSymbol, htmlDecode({ text: language.SHEIN_KEY_PWA_17004 })) // 按元换购
          } else if (r.ruleDimension == 'discount') {
            content.tips.list[i] = template(buyLimitAmount, percentNumWithoutSign(redemptionPriceAmount), htmlDecode({ text: language.SHEIN_KEY_PWA_17002 })) // 按折扣换购
          }
        } else {
          // 满元
          if (r.ruleDimension == 'price') {
            content.tips.list[i] = template(buyLimitAmountWithSymbol, redemptionPriceAmountWithSymbol, htmlDecode({ text: language.SHEIN_KEY_PWA_17005 })) // 按元换购
          } else if (r.ruleDimension == 'discount') {
            content.tips.list[i] = template(buyLimitAmountWithSymbol, percentNumWithoutSign(redemptionPriceAmount), htmlDecode({ text: language.SHEIN_KEY_PWA_17003 })) // 按折扣换购
          }
        }

      })

      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15828

  /**
   * 14.满减
   * 差额提示: 是
   * 多档次: 是
   * @isFullShop 1: 全场满减; 0: 选品满减
   * @type 1: 满X件; 2: 每满X件；3：满X元；4.每满X元
   */
  } else if (typeId == 14) {
    content.lable = language.SHEIN_KEY_PWA_15880
    if (content.rules.length) {
      content.rules.forEach((r, i) => {
        let { type, value, value_amount, discount } = r || {}
        let discountAmountWithSymbol = discount?.value_amount?.amountWithSymbol
        let valueAmountWithSymbol = value_amount?.amountWithSymbol
        let ruleMap = {
          '1': template(discountAmountWithSymbol, value, language.SHEIN_KEY_PWA_15834), // 满 {value} 件，减 {discountAmountWithSymbol} 元。
          '2': template(value, discountAmountWithSymbol, language.SHEIN_KEY_PWA_29489), // 每满 {value} 件，减 {discountAmountWithSymbol} 元。
          '3': template(discountAmountWithSymbol, valueAmountWithSymbol, language.SHEIN_KEY_PWA_15833), // 满 {value_amount.amountWithSymbol} 元，减 {discountAmountWithSymbol}。
          '4': template(valueAmountWithSymbol, discountAmountWithSymbol, language.SHEIN_KEY_PWA_29488), // 每满 {valueAmountWithSymbol} 元，减 {discountAmountWithSymbol}。
        }
        if (ruleMap[type]) {
          content.tips.list[i] = ruleMap[type]
        }
      })
    }
    
    if (rangeList.length) {
      content.tips.text = content.tips.list[0] || ''

      // lable 设置
      if (rules.find(obj => obj.type == 4)) {
        // 每满xxx元活动
        content.lable = template(rangeList[0].buyLimit.amountWithSymbol, rangeList[0].discountValue.amountWithSymbol, language.SHEIN_KEY_PWA_29488)
      } else if (rules.find(obj => obj.type == 2)) { 
        // 每满xxx件活动
        content.lable = template(rangeList[0].buyLimit.amount, rangeList[0].discountValue.amountWithSymbol, language.SHEIN_KEY_PWA_29489)
      } else {
        content.lable = ruleCrondType == 1 ? template(rangeList[0].discountValue.amountWithSymbol, rangeList[0].buyLimit.amountWithSymbol, language.SHEIN_KEY_PWA_15833) : template(rangeList[0].discountValue.amountWithSymbol, rangeList[0].buyLimit.amount, language.SHEIN_KEY_PWA_15834)
      }
    }

  /**
   * 15.x元n件
   * 差额提示: 是
   * 多档次: 是
   * @ruleCrondType 1: x件n元; 2: 每x件n元
   * @diffType 1: 差元; 2: 差件
   */
  } else if (typeId == 15) {
    content.lable = language.SHEIN_KEY_PWA_15880
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        if (ruleCrondType == 1) {
          content.tips.list[i] = template(r.discountValue.amountWithSymbol, r.buyLimit.amount, language.SHEIN_KEY_PWA_15837)
        } else if (ruleCrondType == 2) {
          content.tips.list[i] = template(r.discountValue.amountWithSymbol, r.buyLimit.amount, language.SHEIN_KEY_PWA_15838)
        }
      })
      
      content.tips.text = content.tips.list[0] || ''
      content.lable = template(rangeList[0].discountValue.amountWithSymbol, rangeList[0].buyLimit.amount, language.SHEIN_KEY_PWA_17369)
    }

    /**
     * 16.每买N减N (减元)
     * 差额提示: -
     * 多档次: -
     */
  } else if (typeId == 16) {
    if (rangeList.length) {
      if (rangeList.length == 2) {
        content.tips.list[0] = template(rangeList[0].discount.amountWithSymbol, rangeList[1].discount.amountWithSymbol, language.SHEIN_KEY_PWA_16000)
      } else if (rangeList.length == 1) {
        content.tips.list[0] = template(rangeList[0].discount.amountWithSymbol, language.SHEIN_KEY_PWA_15998)
      }

      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15880

    /**
     * 17.每买N减N (折扣)
     * 差额提示: -
     * 多档次: -
     */
  } else if (typeId == 17) {
    if (rangeList.length) {
      if (rangeList.length == 2) {
        content.tips.list[0] = template(percentNum((100 - rangeList[0].discount.amount * 100).toFixed(0)), percentNum((100 - rangeList[1].discount.amount * 100).toFixed(0)), language.SHEIN_KEY_PWA_15999)
      } else if (rangeList.length == 1) {
        content.tips.list[0] = template(percentNum((100 - rangeList[0].discount.amount * 100).toFixed(0)), language.SHEIN_KEY_PWA_15997)
      }
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15880

    /**
     * 18.买n减n（减元）
     * 差额提示: -
     * 多档次: -
     */
  } else if (typeId == 18) {
    if (integrityPromotionInfo[id]?.rangeList?.length) {
      const rangeList = integrityPromotionInfo[id].rangeList
      if (rangeList.length == 2) {
        content.tips.list[0] = template(rangeList[0].discount.amountWithSymbol, rangeList[1].discount.amountWithSymbol, language.SHEIN_KEY_PWA_16000)
      } else if (rangeList.length == 1) {
        content.tips.list[0] = template(rangeList[0].discount.amountWithSymbol, language.SHEIN_KEY_PWA_15998)
      }

      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15880

  /**
   * 19.买n减n（折扣）
   * 差额提示: -
   * 多档次: -
   * @ruleType sale_discount: 按特价折扣; discount: 按原价折(默认)
   */
  } else if (typeId == 19) {
    if (rangeList.length) {
      if (ruleType == 'sale_discount') {
        if (rangeList.length == 2) {
          content.tips.list[0] = template(percentNumWithoutSign(rangeList[0].discount.amount), percentNumWithoutSign(rangeList[1].discount.amount), language.SHEIN_KEY_PWA_16888)
        } else if (rangeList.length == 1) {
          content.tips.list[0] = template(percentNumWithoutSign(rangeList[0].discount.amount), language.SHEIN_KEY_PWA_16887)
        }
      } else {
        if (rangeList.length == 2) {
          if (['tw', 'hk'].includes(lang)) {
            content.tips.list[0] = template(discountNumCn(rangeList[0].discount.amount), discountNumCn(rangeList[1].discount.amount), language.SHEIN_KEY_PWA_15999)
          } else {
            content.tips.list[0] = template(percentNum(rangeList[0].discount.amount), percentNum(rangeList[1].discount.amount), language.SHEIN_KEY_PWA_15999)
          }
        } else if (rangeList.length == 1) {
          if (['tw', 'hk'].includes(lang)) {
            content.tips.list[0] = template(discountNumCn(rangeList[0].discount.amount), language.SHEIN_KEY_PWA_15997)
          } else {
            content.tips.list[0] = template(percentNum(rangeList[0].discount.amount), language.SHEIN_KEY_PWA_15997)
          }
        }
      }
      
      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15880

    /**
     * 20.订单返券
     * 差额提示: -
     * 多档次: -
     */
  } else if (typeId == 20) {
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        const coupon = r.coupon
        const buyLimitAmountWithSymbol = r.buyLimit.amountWithSymbol
        const couponPriceSymbol = coupon.priceSymbol

        if (coupon.isFreeShipCoupon != '0' || coupon.isMemberFreeShipCoupon != '0') {
          content.tips.list[i] = template(buyLimitAmountWithSymbol, htmlDecode({ text: language.SHEIN_KEY_PWA_15996 }))
        } else if (coupon.couponTypeId == 1) {
          content.tips.list[i] = template(buyLimitAmountWithSymbol, couponPriceSymbol, htmlDecode({ text: language.SHEIN_KEY_PWA_15994 }))
        } else {
          content.tips.list[i] = template(buyLimitAmountWithSymbol, couponPriceSymbol, htmlDecode({ text: language.SHEIN_KEY_PWA_15994 }))
        }
      })

      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15882

  /**
   * 24.打折限购
   * 差额提示: -
   * 多档次: -
   * @ruleType sale_discount: 按特价折扣; discount: 按原价折; '': 非打折
   * @singleNum 单品限购数
   * @orderNum 订单限购数
   */
  } else if (typeId == 24) {
    if (isAsync) {
      singleNum = Number(singleNum)
      orderNum = Number(orderNum)
  
      const discountTip = {
        sale_discount: () => {
          return `${language.SHEIN_KEY_PWA_17320}${singleNum ? template(Number(singleNum), language.SHEIN_KEY_PWA_17321) : ''}${orderNum ? template(Number(orderNum), language.SHEIN_KEY_PWA_17324) : ''}`
        },
        no_discount: () => {
          return `${singleNum && orderNum ? template(Number(singleNum), language.SHEIN_KEY_PWA_17321) : singleNum ? template(Number(singleNum), language.SHEIN_KEY_PWA_17326) : ''}${orderNum ? template(Number(orderNum), language.SHEIN_KEY_PWA_17327) : ''}`
        }
      }
      const setRuleType = ruleType == 'no_discount' ? 'no_discount' : 'sale_discount'
      content.tips.list[0] = discountTip[setRuleType]() || ''
      content.style = ruleType == 'no_discount' ? {
        color: '#000'
      } : {}

      content.tips.text = content.tips.list[0] || ''
      content.tip = content.tips.text + content.difference.text
      content.tipsIndex = 0
      content.lable = ruleType == 'no_discount' ? '' : language.SHEIN_KEY_PWA_17317
    }

  /**
   * 25.满折
   * 差额提示: 非常规
   * 多档次: 是
   * @ruleType sale_discount: 按特价折扣; discount: 按原价折; '': 非打折
   */
  } else if (typeId == 25) {
    if (rangeList.length) {
      rangeList.forEach((r, i) => {
        if (ruleType == 'sale_discount') {
          content.tips.list[i] = template(r.buyLimit.amountWithSymbol, percentNum(r.discountValue.amount), language.SHEIN_KEY_PWA_17755)
        } else {
          content.tips.list[i] = template(r.buyLimit.amountWithSymbol, percentNum(r.discountValue.amount), language.SHEIN_KEY_PWA_17756)
        }
      })

      content.tips.text = content.tips.list[0] || ''
    }
    content.lable = language.SHEIN_KEY_PWA_15880
  /**
   * 28. 满赠（非全场 有品牌）
   * 差额提示: Spend {0} more on {1} items to earn a FREE GIFT
   * 0 差额 1 品牌
   * mainProductRange：1、选品链接ID 2、指定商品SKC 3、全场商品 4、品牌
   * 多档次: 是
   */
  } else if (typeId == 28) {
    
    rangeList.forEach((r, i) => {
      if (mainProductRange == 4) {
        content.tips.list[i] = template(brandName, r.buyLimit.amountWithSymbol, language.SHEIN_KEY_PWA_20395)
      } else {
        content.tips.list[i] = template(r.buyLimit.amountWithSymbol, language.SHEIN_KEY_PWA_20394)
      }
    })
    
    content.lable = mainProductRange == 4 ? template(brandName, language.SHEIN_KEY_PWA_20401) : language.SHEIN_KEY_PWA_15881
    content.tips.text = content.tips.list[0] || ''
  } else if (typeId == 32) {
    content.lable = language.SHEIN_KEY_PWA_26427
  }


  return content
}

export { transformPromotionTips }
