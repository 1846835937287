
import PROMOTION_CONFIG from '../config'
import { transformPromotionTips } from '../logic/transformPromotionTips'
import { PromotionUtils } from '../utils/index'

const {
  NeedDealPromotionTypeIds,
  PriorityPromotionTypeIds, // 活动优先级
  TitlePromotionTypeIds, // 活动标题
  IconPromotionTypeIds, // 活动icon
  BrandProductPromotionTypeIds
} = PROMOTION_CONFIG

export class Module {
  constructor ({ item = {}, integrityPromotionInfo = {}, language = {}, scence = '' } = {}) {
    this.rawModule = {}
    this.integrityPromotionInfo = integrityPromotionInfo
    this.content = {}
    this.register({ item, language, scence })
  }

  register ({ item, language, scence }) {
    const promotionInfo = item.promotionInfo
    this.rawModule = promotionInfo?.length ? promotionInfo.map(p => {
      const newItem = {}
      const { typeId, id, buyLimit, soldNum, promotion_logo_type, promotionLogoType } = p
      // 31品类限定商家限时直降 wiki.pageId=1198803653
      const isStoreLimitTimePromotion = +typeId !== 31 || promotion_logo_type === 3 || promotionLogoType === 3
      // 普通活动
      if (PriorityPromotionTypeIds.includes(+typeId) && isStoreLimitTimePromotion) {
        const params = { p, language, scence, options: {} }
        
        let obj = {}
        buyLimit && !p.originBuyLimit && (obj.originBuyLimit = buyLimit)
        soldNum && !p.originSoldNum && (obj.originSoldNum = soldNum)
        params.options = PromotionUtils.assignOptions({ item, p: Object.assign(p, this.integrityPromotionInfo[id] || {}, obj ) })
        const content = this.transformPromotionsContent(params)
        Object.assign(newItem, p, content, this.integrityPromotionInfo[id] || {})
      }
      newItem.typeId = Number(typeId)
      newItem.sortIndex = PriorityPromotionTypeIds.indexOf(Number(typeId))
      return newItem
    }).sort((a, b) => a.sortIndex - b.sortIndex) : []

    if (this.rawModule.length > 1 && this.content.typeId == 14 && this.integrityPromotionInfo[this.content.id]?.isFullShop == '1' ) {
      // 全场满减（特殊的活动）
      this.content  = this.rawModule.find(item => !(item.typeId == 14 && item.isFullShop == '1'))
    }
  }

  transformPromotionsContent ({ p, language, cartItem, scence, options = {} } = {}) {
    const { typeId, id, brandName = '', mainProductRange } = p
    // 会员折扣商品
    this.content.isVipItem = Boolean(typeId == 12)
    // 折上折商品
    this.content.isMoreDiscount = Boolean(options.isMoreDiscount)
    // 限购商品
    this.content.isLimitedItem = Boolean(options.isLimitedItem)
    // 促销标题
    Object.entries(TitlePromotionTypeIds).some(([languageKey, promotionTypeIds]) => {
      if (promotionTypeIds.includes(+typeId)) {
        // mainProductRange: 1、选品链接ID 2、指定商品SKC 3、全场商品 4、品牌
        this.content.title = `${(mainProductRange == 4 && BrandProductPromotionTypeIds.includes(+typeId)) ? brandName : '' } ${language[languageKey]}`

        return true
      }
    })
    // 促销icon
    Object.entries(IconPromotionTypeIds).some(([icon, promotionTypeIds]) => {
      if (promotionTypeIds.includes(+typeId)) {
        this.content.icon = icon
        return true
      }
    })
    // 倒计时data
    if ([3, 8].includes(+typeId)) {
      this.content.countDownSingle = this.integrityPromotionInfo[id]?.integrityContent?.countDown || undefined
    } else if ([10, 11].includes(+typeId)) {
      const { isCountdown, endTimestamp } = p
      this.content.countDown = isCountdown == '1' ? typeof window != 'undefined' ? PromotionUtils.countDownTime({ endTimestamp }) : endTimestamp * 1000 : ''
    } else {
      this.content.countDown = this.integrityPromotionInfo[id]?.integrityContent?.countDown
    }

    // 促销提示语
    if (NeedDealPromotionTypeIds.includes(+typeId)) {
      const option = { id, typeId, language, integrityPromotionInfo: this.integrityPromotionInfo, scence, options, originPromotionInfo: p }
      const content = transformPromotionTips(option)
      this.content = Object.assign(this.content, content)
    }
    return Object.assign({}, this.content)
  }
}
