import { discountPercentNum } from '@shein/common-function'
export function percentNum (num) {
  let newNum = parseInt(100 - Math.round(100 * num + Number.EPSILON)).toFixed(0)
  if ( typeof window != 'undefined') {
    return discountPercentNum({ val: newNum })
  }
  return `${newNum}%`
}

export function percentNumWithoutSign (num) {
  return `${parseInt(100 - Math.round(100 * num + Number.EPSILON)).toFixed(0)}`
}

export function discountNumCn (num) {
  return `${parseInt(num * 10).toFixed(2) * 1}`
}

export function logBrowser (title = '', content = '') {
  console.log(`[PromotionCenter] ${title}`, content)
}

export function percentNumSign (num) {
  let newNum = num ? parseInt(num).toFixed(0) : ''
  if(!newNum) return ''
  if ( typeof window != 'undefined' ) {
    return discountPercentNum({ val: newNum })
  }
  return `${newNum}%`
}


export function decimalToPercent(num) {
  let newNum = parseInt(100 - Math.round(100 * num + Number.EPSILON)).toFixed(0)
  return `${newNum}%`
}


export function numberToPercentage(num) {
  let newNum = num ? parseInt(num).toFixed(0) : ''
  if(!newNum) return ''
  return `${newNum}%`
}
