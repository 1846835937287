import * as commonUtils from './common'
import { replaceHrefSpecChar, stringifyQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

const { langPath } = gbCommonInfo

const UtilCache = {
  scLinkMap: {
    // scId: 'www.shein.com/p-sc-scId'
  },
  get (module, key) {
    if (this[module]) {
      return this[module][key]
    } else {
      console.error('Module does not exist.')
    }
  },
  set (module, key, value) {
    if (this[module]) {
      this[module][key] = value
    } else {
      console.error('Module does not exist.')
    }
  }
}

/**
 * 计算倒计时
 */
function countDownTime ({ endTimestamp = 0 }) {
  const end = endTimestamp * 1000
  const now = (new Date()).getTime()
  const diffTime = end - now
  const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24) // 相差天数
  const diffHours = Math.floor(diffTime / 1000 / 60 / 60 - diffDays * 24) // 相差小时
  const diffMinutes = Math.floor(diffTime / 1000 / 60 - diffDays * 24 * 60 - diffHours * 60) // 相差分钟
  const diffSeconds = Math.floor(diffTime / 1000 - diffDays * 24 * 60 * 60 - diffHours * 60 * 60 - diffMinutes * 60) // 相差秒数

  return {
    diffTime,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds
  }
}

/**
 * 生成活动未达成跳转链接
 * @param {String} scId [选品id]
 */
function promotionLink ({
  scId, promotion_id, typeId, mall_code = '', flash_type = 1, url_prams = '',
  entry_from = '',
  src_module = '',
  src_identifier = '',
  src_tab_page_id = '',
}, extraData = {}) {
  return new Promise((resolve) => {
    if (typeId == 10) {
      if (flash_type == 6) { // 品牌特卖
        const queryObj = {
          promotion_id,
          entry_from,
          src_module,
          src_identifier,
          src_tab_page_id,
        }
        let url = `${langPath}/brand-zone-promotion?${stringifyQueryString({ queryObj })}`
        resolve(url)
      } else {
        let url = `${langPath}/flash-sale.html?flash_type=${flash_type}`
        if (mall_code) {
          url += `&mallCode=${mall_code}`
        }
        resolve(url)
      }
    }  else if (typeId == 30) {
      let url = `${langPath}/under-prices-products.html?${url_prams}`
      resolve(url)
    } else if (typeId == 11) {
      if(extraData?.IS_RW) return resolve('')
      schttp({
        url: '/api/live/liveOverview/get'
      }).then(data => {
        let url = ''
        let liveId = ''
        if(data.code == 0) { 
          liveId = data?.info?.liveId
          url = `${langPath}/live/${liveId}?data=${encodeURIComponent(JSON.stringify({
            page_from_sa: 31, // 约定来自购物车传31
            shareData: '',
            goods_id: extraData?.item?.product?.goods_id || '',
            live_id: liveId,
            goods_name: extraData?.item?.product?.goods_name || '',
            goods_image_url: extraData?.item?.product?.goods_img || '',
            uniteprice: extraData?.item?.unitPrice || {},
            mall_code: extraData?.item?.mall_code || '',
            discount: extraData?.item?.product?.unit_discount || '',
          }))}`
        }
        resolve(url)
      }).catch(() => resolve(''))
    } else if (scId) {
      // 优先取缓存
      if (UtilCache.get('scLinkMap', scId)) {
        resolve(UtilCache.get('scLinkMap', scId))
      } else {
        schttp({
          url: '/api/productInfo/selectInfoByScId/get',
          params: {
            sc_id: scId
          }
        }).then( data => {
          if (data && data.info && data.info.scInfo && data.info.scInfo.length) {
            const { sc_url_id, select_name, select_type_name, sc_url } = data.info.scInfo[0]
            let url = ''
            if (sc_url) {
              url = `${langPath}${sc_url}`
            } else if (sc_url_id && select_name && select_type_name) {
              url = `${langPath}/${select_type_name}/${select_name}-sc-${sc_url_id}.html`
            }
            if (mall_code) {
              url += (url.indexOf('?') > -1 ? '&mallCode=' : '?mallCode=') + mall_code
            }
            UtilCache.set('scLinkMap', scId, url)
            resolve(replaceHrefSpecChar({ href: url }))
          } else {
            resolve('')
          }
        })
      }
    } else {
      resolve('')
    }
  })
}

/**
 * 未满足档次跳转
 * @param {Object} item [促销数据]
 */
async function jumpToAdd (item, source) {
  if (item.typeId == 10) {
    const { flashType, flash_type } = item
    const type = flashType || flash_type
    let url = `${langPath}/flash-sale.html?flash_type=${type}`
    if (item.mall_code) {
      url += `&mallCode=${item.mall_code}`
    }

    // 如果是从商详去闪购列表，替换location.href,让闪购页刷新
    if(url && _isFromGoodsDetailToFlash(source, url)) {
      window.location.href = url
      return
    }

    window._gb_app_
      ? window._gb_app_.$router.push(url)
      : window.location.href = url
  } else if (item.scId) {
    // const newWin = window.open('', '_self')
    let url = await promotionLink(item)
    if (url && source) {
      url += (url.indexOf('?') > -1 ? '&productsource=' : '?productsource=') + source
    }

    // 如果是从商详去闪购列表，替换location.href,让闪购页刷新
    if(url && _isFromGoodsDetailToFlash(source, url)) {
      window.location.href = url
      return
    }

    url && (window._gb_app_
      ? window._gb_app_.$router.push(`${url}`)
      : window.location.href = `${url}`)
  }
}

/**
 * 是否从商详去闪购列表
 * @param {String} source [源场景]
 * @param {String} toUrl [目标url]
 */
function _isFromGoodsDetailToFlash(source = '', toUrl = '') {
  return source === 'goodsDetail' && toUrl?.includes?.('flash-sale.html')
}

/**
 * 促销活动标识处理
 * @param {Object} item [商品数据]
 * @param {Object} p [促销信息]
 */
function assignOptions ({ item, p }) {
  const { original_discount = '', unit_discount = '' } = item
  let options = {}
  if (p.typeId == 3) {
    let isMoreDiscount = original_discount && unit_discount && Number(original_discount) !== 0 && Number(original_discount) != Number(unit_discount)
    options.isMoreDiscount = isMoreDiscount
  }
  if ([10, 11].includes(Number(p.typeId)) || p.typeId == 24 && p.ruleType == 'no_discount' && !Number(p.orderNum) && Number(p.singleNum)) {
    options.isLimitedItem = true
  }
  return options
}


export const PromotionUtils = {
  ...commonUtils,
  countDownTime,
  promotionLink,
  jumpToAdd,
  assignOptions
}
